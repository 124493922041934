import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
import i18n from './i18n'
import moment from 'moment'
// import router from './router'
// Vue.use(require('vue-moment'));

export default new Vuex.Store({
    state: {
        user: null,
        account: null,
        accountDefault: {
            about: null,
            video: null,
            contact: {
                email: null,
                phone: null,
                webpage: null,
                facebook: null,
                street: null,
                postcode: null,
                city: null,
                country: null,
            },
            brand: {
                logo: null,
                colorMain: '#cccccc',
                colorLinks: '#555555',
            },
            locale: {
                language: 'en',
                currency: 'NOK',
                // timezone: 1,
            },
            offer: {
                heading: 'Offer details',
                text: 'You have been sent the following offer. Please look over the details. We look forward to hearing from you the coming days.',
                acceptButton: 'Accept Offer',
                acceptLegal: 'By accepting the offer you are legally bound to proceed with the terms and pricing of the current offer.',
                declineText: 'Decline Offer',
            },
            hibernate: {
                days: 10,
            },
            send: {
                subject: 'You have an offer',
                body: `Hello {{contact.name}}\n\nWe are happy to inform you that you have received an offer. Follow this link to see it right now:\n{{offer.url}}\n\nRegards,\n{{account.name}}`,
            },
            followup: {
                days: 3,
                subject: 'Do you remember we sent you an offer?',
                body: `Hi again {{contact.name}}\n\nWe just wanted to follow up the offer we sent you. Follow this link to see the offer:\n{{offer.url}}\n\nRegards,\n{{account.name}}`,
            },
            expire: {
                days: 7,
                remindDaysBefore: 1,
                subject: 'Your offer is about to expire',
                body: `Hi again {{contact.name}}\n\nWe just wanted to remind you of the offer we sent you, and that it is about to expire.\nFollow this link to see the offer:\n{{offer.url}}\n\nRegards,\n{{account.name}}`,
            },
            feedback: {
                accept: {
                    text: 'Thank you for the accept',
                    subject: 'Thank you for the feedback',
                    body: `Hello {{contact.name}}\n\nThank you for the feedback on the offer. We are happy to hear you accepted the offer. We will get in touch very soon to follow up the deal.\nYour IP address at time of accept was: {{sourceIp}}.\n\nRegards,\n{{account.name}}`,
                },
                decline: {
                    text: 'Your decline of the offer has been registered',
                    subject: 'Thank you for the feedback',
                    body: `Hello {{contact.name}}\n\nThank you for the feedback on the offer. We are sorry to hear you declined the offer. Hope to do business with you in the future.\n\nRegards,\n{{account.name}}`,
                },
            },
            messages: {
                contact: {
                    name: '',
                    title: '',
                    image: null,
                },
                welcomeMessage: `Hi there. If you have any questions regarding the offer please contact me directly here. I will reply as soon as I can during normal business hours`,
            },
            integrations: {
                webhooks: [],
            }
        },
        users: null,
        offers: null,
        products: null,
        customers: null,
        messages: null,
        notes: null,
        trackings: null,
        wSConnectionLastPong: 0,
        wSConnectionPollingInterval: null,
        showDisconnected: null,
        accountChatMessages: null,
        templates: [
            {
                templateId: 1,
                name: 'Speed',
                description: 'A design that looks fast and rapid!',
                styles: {
                    page: {
                        style: {
                            backgroundColor: '#0000ff',
                            color: '#000',
                            fontFamily: 'Serif',
                            fontSize: '14px',
                        },
                    },
                    heading: {
                        style: {
                            color: '#00ff00',
                            fontFamily: 'Lato',
                            fontSize: '2em',
                        },
                    },
                    decoration: {
                        style: {
                            color: '#00ff00',
                        },
                    },
                },
            }
        ],
        articles: null,
        isMobile: false,
        isWebView: false,
    },
    
    getters: {
        
        getAccount: state => {
            return state.account;
        },
        
        getAccountChatMessages: state => {
            return state.accountChatMessages;
        },
        
        getAccountDefault: state => {
            return state.accountDefault;
        },
        
        getUser: state => {
            return state.user;
        },
        
        getUsers: state => {
            return state.users;
        },
        
        
        
        
        getMessages: state => {
            if (state.messages) {
                if (state.messages.length == 0) {
                    return []
                }
                else {
                    return state.messages
                        .sort((a, b) => a.receivedAt - b.receivedAt)
                        // .reverse();
                }
            }
        },
        
        getOffers: state => {
            if (state.offers) {
                if (state.offers.length == 0) {
                    return []
                }
                else {
                    return state.offers
                        .sort((a, b) => a.updatedAt - b.updatedAt)
                        .reverse();
                }
            }
        },
        
        getProducts: state => {
            // console.log('getProducts');
            
            if (state.products) {
                if (state.products.length == 0) {
                    return []
                }
                else {
                    
                    const products = state.products
                        .sort((a, b) => a.updatedAt - b.updatedAt)
                        .reverse()
                        .map( product => {
                            if (
                                !product.images || 
                                !product.images.length) {
                            
                                product.coverUrl = null;
                            }
                            
                            let coverImage;
                            
                            if (product.images) {
                                coverImage = product.images.find( cI => { 
                                    if (
                                        product.cover && 
                                        product.cover == cI.fileId) {
                                        return cI;
                                    }
                                });
                            }
                            
                            if (coverImage) {
                                product.coverUrl = process.env.VUE_APP_FILES_ENDPOINT + '/'+state.account.accountId+'/' + coverImage.fileId +'.'+ coverImage.extension;
                            }
                            else if (product.images && product.images.length){
                                product.coverUrl = process.env.VUE_APP_FILES_ENDPOINT + '/'+state.account.accountId+'/' + product.images[0].fileId +'.'+ product.images[0].extension;
                            }
                            else {
                                product.coverUrl = null;
                            }
                            
                            return product;
                        });
                    
                    return products;
                }
            }
        },
        
        getCustomers: state => {
            if (state.customers) {
                if (state.customers.length == 0) {
                    return []
                }
                else {
                    return state.customers
                        .sort((a, b) => a.updatedAt - b.updatedAt)
                        .reverse();
                }
            }
        },
        
        getTemplates: state => {
            return state.templates
        },
        
        getTrackings: state => {
            if (state.trackings) {
                if (state.trackings.length == 0) {
                    return []
                }
                else {
                    return state.trackings
                        .sort((a, b) => a.updatedAt - b.updatedAt)
                        .reverse()
                }
            }
        },
        
        getArticles: state => {
            if (state.articles) {
                return state.articles.items;
            }
            return;
        },
        
        getNotes: state => {
            if (state.notes) {
                if (state.notes.length == 0) {
                    return []
                }
                else {
                    return state.notes
                        .sort((a, b) => a.updatedAt - b.updatedAt)
                        .reverse()
                }
            }
        },
        
        getIsMobile: state => {
            return state.isMobile;
        },
        
        getIsWebView: state => {
            return state.isWebView;
        },
        
        getWSConnectionLastPong: state => {
            return state.wSConnectionLastPong;
        },
        
        getShowDisconnected: state => {
            return state.showDisconnected;
        },
    },
    
    mutations: {
        setUser(state, user){
            // console.log('mutation user', user);
            state.user = user;
            
            if (user) {
                analytics.identify( user.username, user.attributes);
            }
        },
        
        setAccount(state, account){
            state.accountChatMessages = [];
            
            state.account = Object.assign(
                {},
                state.accountDefault,
                account,
            );
        },
        
        // setWSConnection(state, wSConnection){
        //     state.wSConnection = wSConnection;
        // },
        
        updateAccount(state, account){
            state.account = account;
        },
        
        updateUser(state, user){
            state.users = state.users.map(u => {
                if (u.Username == user.username) {
                    u.info = user
                }
                return u;
            });
        },
        
        setUsers(state, users){
            state.users = users;
        },
        
        setOffers(state, offers){
            // state.offers = offers;
            
            
            state.offers = offers.map(o => {
                let existing = state.offers ? state.offers.find( sO => sO.offerId == o.offerId ) : null;
                
                if ( !existing || existing.updatedAt < o.updatedAt ) {
                    return o;
                }
                else {
                    return existing;
                }
            });
        },
        
        createOffer(state, offer){
            // if an offer is saved as a draft, then remove a potential old draft, and insert new
            state.offers = state.offers.filter( o => o.offerId != offer.offerId);
            state.offers.unshift( offer );
        },
        
        updateOffer(state, updatedOffer){
            console.log('updatedOffer', updatedOffer);
            
            state.offers = state.offers.map(o => {
                if (o.offerId == updatedOffer.offerId) {
                    o = updatedOffer
                }
                return o;
            });
            
            console.log('state.offers offer', state.offers.find(o => o.offerId == updatedOffer.offerId) );
        },
        
        setOffer(state, offerFromGet){
            state.offers = state.offers.map(o => {
                if (o.offerId == offerFromGet.offerId) {
                    o = offerFromGet
                }
                return o;
            });
        },
        
        setProducts(state, products){
            state.products = products;
        },
        
        createProduct(state, product){
            // console.log('createProduct product', product);
            state.products.unshift( product );
        },
        
        updateProduct(state, updatedProduct){
            state.products = state.products.map(p => {
                if (p.productId == updatedProduct.productId) {
                    p = updatedProduct
                }
                return p;
            });
        },
        
        
        
        setCustomers(state, customers){
            // console.log('customers', customers);
            state.customers = customers;
        },
        
        createCustomer(state, customer){
            state.customers.unshift( customer );
        },
        
        updateCustomer(state, updatedCustomer){
            state.customers = state.customers.map(c => {
                if (c.customerId == updatedCustomer.customerId) {
                    c = updatedCustomer
                }
                return c;
            });
        },
        
        
        setMessages(state, messages){
            state.messages = messages;
        },
        
        createMessage(state, message){
            state.messages.push( message );
        },
        
        updateMessage(state, updatedMessage){
            state.messages = state.messages.map(m => {
                if (m.messageId == updatedMessage.messageId) {
                    m = updatedMessage
                }
                return m;
            });
        },
        
        setTrackings(state, trackings){
            state.trackings = trackings;
        },
        
        setIsMobile(state, isMobile){
            state.isMobile = isMobile;
            // console.log('setMobile', isMobile);
        },
        
        setIsWebView(state, isWebView){
            state.isWebView = isWebView;
            console.log('setWebView', isWebView);
        },
        
        setShowDisconnected(state, showDisconnectedObject){
            state.showDisconnected = showDisconnectedObject;
        },
        
        setNotes(state, notes){
            // console.log('notes', notes);
            state.notes = notes;
        },
        
        createNote(state, note){
            state.notes.unshift( note );
        },
        
        updateNote(state, updatedNote){
            state.notes = state.notes.map(n => {
                if (n.noteId == updatedNote.noteId) {
                    n = updatedNote
                }
                return n;
            });
        },
        
        
        setArticles(state, articles){
            state.articles = articles;
        },
        
        addAccountChatMessage(state, message){
            state.accountChatMessages.push( message );
        },
    },
    
    actions: {
        async setAccount(context, account){
            this.commit('setAccount', account);
            
            if (account) {
                // console.log('Account was dispatched', account);
                
                if (account.locale && account.locale.language) {
                    i18n.locale = account.locale.language;
                    
                    switch (account.locale.language) {
                        case 'no':
                        case 'nb':
                            moment.locale('nb');
                            break;
                        case 'en':
                            moment.locale('en');
                            break;
                        default:
                            moment.locale('en');
                    }
                }
                
                // console.log('$i18n', i18n);
                
                const accountPromises = [
                    context.dispatch('listUsers'),
                    context.dispatch('listOffers'),
                    context.dispatch('listProducts'),
                    context.dispatch('listCustomers'),
                    context.dispatch('listNotes'),
                    context.dispatch('listMessages', {method: 'listActiveOffers'}),
                    context.dispatch('listTrackings', {method: 'listLatest'}),
                ];
                
                const promisesResult = await Promise.all( accountPromises );
                // console.log('setAccount promisesResult done');
                
                if (account && account.accountId ) {
                    analytics.group( account.accountId, {
                        name: account.name || null,
                        contact: account.contact || null,
                    });
                }
                
                context.dispatch('webSocketConnect');
                
                return promisesResult;
            }
            else {
                console.log('no account was dispatched');
            }
        },
        
        webSocketConnect(context){
            try {
                console.log('webSocketConnect() called!');
                window.wSConnection = null;
                // console.log(window.wSConnection);
                
                if (context.state.wSConnectionPollingInterval) {
                    clearInterval( context.state.wSConnectionPollingInterval );
                }
                
                const channelId = context.state.account.accountId;
                
                if (channelId) {
                    // this.loading = 'connecting';
                    window.wSConnection = new WebSocket( process.env.VUE_APP_WS_ENDPOINT+'?channel='+channelId+'&username='+context.state.user.username);
                    // this.commit('setWSConnection', wSConnection);
                    
                    window.wSConnection.onopen = (event) => {
                        const onOpenId = Math.round( Math.random()*1000 );
                        console.log('webSocketConnect() onopen id '+onOpenId);
                        // context.state.wSConnectionLastPong = new Date();
                        // 
                        // context.state.wSConnectionPollingInterval = setInterval( () => {
                        //     console.log('onOpenId '+onOpenId);
                        //     // how long since last context.state.wSConnectionLastPong?
                        //     const millisecondsOld = new Date () - context.state.wSConnectionLastPong;
                        //     console.log('millisecondsOld: ' +millisecondsOld);
                        // 
                        //     if ( millisecondsOld > (1000*60*10) ) { //1000*60*10 minutes
                        //         window.wSConnection.close(1000, 'Pong is too old. Should refresh browser.');
                        //         // clearInterval( context.state.wSConnectionPollingInterval );
                        //         // this.commit('setShowRefresh', 'You have been disconnected from the server in over 1 minute.'); //'+Math.round(millisecondsOld/1000) +'
                        //         return;
                        //     }
                        // 
                        //     // window.wSConnection.send('ping');
                        // }, 5000);
                        
                        context.state.wSConnectionLastPong = new Date();
                        
                        context.state.wSConnectionPollingInterval = setInterval( () => {
                            // how long since last context.state.wSConnectionLastPong?
                            const millisecondsOld = new Date () - context.state.wSConnectionLastPong;
                            // console.log('millisecondsOld: ' +millisecondsOld);
                            // 
                            // if ( millisecondsOld > (1000*60*10) ) { //1000*60*10 minutes
                            //     window.wSConnection.close(1000, 'Pong is too old. Should refresh browser.');
                            //     // clearInterval( context.state.wSConnectionPollingInterval );
                            //     // this.commit('setShowRefresh', 'You have been disconnected from the server in over 1 minute.'); //'+Math.round(millisecondsOld/1000) +'
                            //     return;
                            // }
                            if (window.wSConnection.readyState === window.wSConnection.OPEN) {
                                // console.log('We think you are connected to websocket...');
                            }
                            else {
                                console.log('webSocketSend closed! will try to reconnect');
                                context.dispatch('webSocketConnect');
                            }
                            // window.wSConnection.send('ping');
                        }, 2000);
                    };
                    
                    window.wSConnection.onmessage = (event) => {
                        // console.log('onmessage--->');
                        // const data = event.data;
                        // console.log(data);
                        
                        // if (event.data == 'pong') {
                        //     context.state.wSConnectionLastPong = new Date();
                        //     // new Audio('/assets/pong2.mp3').play(); 
                        //     // https://notificationsounds.com/notification-sounds
                        // }
                        // else if (event.data == 'reconnect') {
                        //     console.log('reconnect');
                        //     window.wSConnection.close(1000, 'Got "reconnect" from pong');
                        //     clearInterval( context.state.wSConnectionPollingInterval );
                        //     context.dispatch('webSocketConnect');
                        // }
                        // else {
                        //     context.dispatch('onWebSocketMessage', event);
                        // }
                        context.dispatch('onWebSocketMessage', event);
                    };
                    
                    window.wSConnection.close = (code, reason) => {
                        console.log('window.wSConnection.close');
                        console.log(code);
                        console.log(reason);
                        
                        // this.commit('setShowRefresh', 'You have been disconnected from the server. Will try to refresh');
                        
                        // const r = confirm("ok to reconnect?");
                        // if (r == true) {
                        //     console.log('will refresh!');
                        // } 
                        // clearInterval( context.state.wSConnectionPollingInterval );
                        // context.dispatch('webSocketConnect');
                        
                        
                        
                        // clearInterval( context.state.wSConnectionPollingInterval );
                        // alert('webSocket is closed');
                        // console.log('window.wSConnection.close');
                        // console.log(code);
                        // console.log(reason);
                        // 
                        // if (window.wSConnection.readyState === WebSocket.OPEN) {
                        //     console.log('already open?');
                        // }
                        // else {
                        //     console.log('trying to reconnect webSocketConnect()');
                        //     // context.dispatch('webSocketConnect');
                        // }
                    };
                }
            } 
            catch (err) {
                console.error('websocket connection failed...');
                console.error(err);
            }
        },
        
        onWebSocketMessage(context, event ){
            // console.log('onmessage event from websocket');
            // console.log(event);
            try {
                const data = JSON.parse(event.data);
                // console.log(data);
                
                if (!data.type) {
                    return;
                }
                
                switch (data.type) {
                    case 'accountChatMessage':
                        this.commit('addAccountChatMessage', data);
                        new Audio('/assets/message.mp3').play();
                        break;
                        
                    // Account
                    case 'updateAccount':
                        this.commit('updateAccount', data.content);
                        break;
                        
                    // User
                    case 'updateUser':
                        this.commit('updateUser', data.content);
                        break;
                        
                    // Product
                    case 'createProduct':
                        this.commit('createProduct', data.content);
                        break;
                    case 'updateProduct': // also updating from 'deleteProduct';
                        this.commit('updateProduct', data.content);
                        // if (data.content.) {
                        //     this.commit('deleteProduct', data.content);
                        // }
                        // else {
                        //     this.commit('updateProduct', data.content);
                        // }
                        break;
                        
                    // Offer
                    case 'createOffer':
                        this.commit('createOffer', data.content);
                        break;
                    case 'updateOffer': // also updating from 'deleteOffer';
                        this.commit('updateOffer', data.content);
                        // if (data.content.Attributes) {
                        //     this.commit('deleteOffer', data.content);
                        // }
                        // else {
                        //     this.commit('updateOffer', data.content);
                        // }
                        break;
                        
                    // Customer
                    case 'createCustomer':
                        this.commit('createCustomer', data.content);
                        break;
                    case 'updateCustomer': // also updating from 'deleteCustomer';
                        this.commit('updateCustomer', data.content);
                        // if (data.content.Attributes) {
                        //     this.commit('deleteCustomer', data.content);
                        // }
                        // else {
                        //     this.commit('updateCustomer', data.content);
                        // }
                        break;
                        
                    // Notes
                    case 'createNote':
                        this.commit('createNote', data.content);
                        break;
                    case 'updateNote': // also updating from 'deleteNote';
                        this.commit('updateNote', data.content);
                        // if (data.content.Attributes) {
                        //     this.commit('deleteNote', data.content);
                        // }
                        // else {
                        //     this.commit('updateNote', data.content);
                        // }
                        break;
                        
                    // Messages
                    case 'createMessage':
                        this.commit('createMessage', data.content);
                        if (data.content.from != 'support') {
                            try {
                                window.navigator.vibrate([200, 100, 200]);
                            } 
                            catch (e) {
                                console.log('navigator vibrate');
                                console.log(e);
                            }
                            new Audio('/assets/message.mp3').play();
                        }
                        break;
                    case 'updateMessage':
                        this.commit('updateMessage', data.content);
                        break;
                    
                    default:
                        console.log('onWebSocketMessage event.data.type is not recognized.');
                        console.log(event);
                }
            } 
            catch (e) {
                console.log('WebSocket data not JSON format');
                // console.error(e);
            }
        },
        
        webSocketSend(context, data ){
            try {
                if (window.wSConnection.readyState === window.wSConnection.OPEN) {
                    window.wSConnection.send( 
                        JSON.stringify({
                            channelId: context.state.account.accountId,
                            sender: context.state.user.attributes.email,
                            ...data,
                        })
                    );
                }
                else {
                    console.log('webSocketSend closed! will try to reconnect');
                    context.dispatch('webSocketConnect');
                }

            } 
            catch (err) {
                console.log('webSocketSend error!');
                console.error(err);
            } 
        },
        
        
        
        async updateAccount(context, account){
            const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.accountId, account);
            // this.commit('updateAccount', response.data);
            
            // context.dispatch('webSocketSend', {
            //     type: 'updateAccount',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'updateAccount', response.data);
                return response.data;
            }
        },
        
        
        
        // users
        async listUsers(context){
            const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/users');
            
            // console.log('listUsers called');
            // console.log(response.data);
            
            if (response.data) {
                this.commit('setUsers', response.data);
            }
        },
        
        async updateUser(context, user){
            const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/users/' + context.state.user.username, user);
            
            if (response.data) {
                analytics.track( 'updateUser', response.data);
                return response.data;
            }
        },
        
        
        // offers
        async listOffers(context){
            const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/offers');
            
            if (response.data) {
                this.commit('setOffers', response.data.Items);
            }
        },
        
        async createOffer(context, offer){
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/offers/', offer);
            // console.log('createOffer response.data', response.data);
            
            // console.log('store.js response.data ', response.data);
            // this.commit('createOffer', response.data);
            // context.dispatch('webSocketSend', {
            //     type: 'createOffer',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'createOffer', response.data);
                return response.data;
            }
        },
        
        async updateOffer(context, offer){
            const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/offers/' + offer.offerId, offer);
            // this.commit('updateOffer', response.data);
            // context.dispatch('webSocketSend', {
            //     type: 'updateOffer',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'updateOffer', response.data);
                return response.data;
            }
        },
        
        async deleteOffer(context, offer){
            const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/offers/' + offer.offerId);
            // if (response.data && response.data.Attributes) {
            //     const updatedOffer = Object.assign(
            //         offer,
            //         response.data.Attributes
            //     );
            //     // this.commit('updateOffer', updatedOffer);
            // 
            //     // context.dispatch('webSocketSend', {
            //     //     type: 'updateOffer',
            //     //     content: updatedOffer
            //     // });
            // 
            //     return updatedOffer;
            // }
            if (response.data) {
                analytics.track( 'deleteOffer', response.data);
                return response.data;
            }
        },
        
        // async getOfferTracking(context, offerId){
        //     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/offers/' + offerId);
        // 
        //     if (response.data && response.data[0]) {
        //         this.commit('setOffer', {
        //             ...response.data[0].Item,
        //             // tracking: response.data[1].Items.sort((a, b) => a.initAt - b.initAt).reverse(),
        //         });
        // 
        //         return {
        //             ...response.data[0].Item,
        //             tracking: response.data[1].Items.sort((a, b) => a.initAt - b.initAt).reverse(),
        //         }
        //     }
        // },
        
        async getOffer(context, offerId){
            const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/offers/' + offerId);
            
            if (response.data) {
                // [0] = offer
                    if (response.data[0] && response.data[0].status == 'fulfilled') {
                        // console.log('offer:');
                        // console.log(response.data[0].value.Item);
                        if (response.data[0].value.Item) {
                            this.commit('updateOffer', response.data[0].value.Item);
                        }
                    }
                // [1] = all offer trackings
                    if (response.data[1] && response.data[1].status == 'fulfilled') {
                        const allOtherTrackings = context.getters.getTrackings.filter(t => !t.trackingId.startsWith( offerId ) );
                        const offerTrackings = response.data[1].value.Items;
                        
                        this.commit('setTrackings', [
                            ...allOtherTrackings,
                            ...offerTrackings,
                        ]);
                    }
                // [2] = all offer messages
                    if (response.data[2] && response.data[2].status == 'fulfilled') {
                        const allOtherMessages = context.getters.getMessages.filter(t => !t.messageId.startsWith( offerId ) );
                        const offerMessages = response.data[2].value.Items;
                        
                        this.commit('setMessages', [
                            ...allOtherMessages,
                            ...offerMessages,
                        ]);
                    }
            }
        },
        
        
        // trackings API START
        async listTrackings(context, data = {}){
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/trackings', data);
            if (response && response.data && response.data.Items) {
                this.commit('setTrackings', response.data.Items);
                // return response.data;
            }
        },
        // trackings API END
        
        
        async createMessage(context, message){
            const data = {
                method: 'createMessage',
                message,
            };
            
            // console.log('action createMessage. Then wait for response by websocket to send message.');
            // console.log(data);
            
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/messages', data);
            // // this.commit('createMessage', response.data);
            // 
            // context.dispatch('webSocketSend', {
            //     type: 'createMessage',
            //     content: response.data
            // });
            // 
            
            if (response.data) {
                analytics.track( 'createMessage', response.data);
                return response.data;
            }
        },
        
        async listMessages(context){
            const data = {
                method: 'listActiveOffers',
            };
            
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/messages', data);
            // console.log('listMessages called', response.data);
            
            if (response.data) {
                this.commit('setMessages', response.data);
            }
        },
        
        async updateMessage(context, message){
            const data = {
                method: 'updateMessage',
                message,
            };
            
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/messages', data);
            
            this.commit('updateMessage', response.data);
            // 
            // context.dispatch('webSocketSend', {
            //     type: 'updateMessage',
            //     content: response.data
            // });
            // 
            
            if (response.data) {
                // analytics.track( 'updateMessage', response.data); // no need to track this...
                return response.data;
            }
        },
        
        
        
        
        
        async listProducts(context){
            const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/products');
            
            if (response.data) {
                this.commit('setProducts', response.data.Items);
            }
        },
        
        async createProduct(context, product){
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/products/', product);
            // this.commit('createProduct', response.data);
            // context.dispatch('webSocketSend', {
            //     type: 'createProduct',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'createProduct', response.data);
                return response.data;
            }
        },
        
        async updateProduct(context, product){
            product.price = parseFloat(product.price) || 0;
            const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/products/' + product.productId, product);
            // console.log('response');
            // console.log( response.data );
            // this.commit('updateProduct', response.data);
            
            // context.dispatch('webSocketSend', {
            //     type: 'updateProduct',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'updateProduct', response.data);
                return response.data;
            }
        },
        
        async deleteProduct(context, product){
            const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/products/' + product.productId);
            // if (response.data && response.data.Attributes) {
            //     const updatedProduct = Object.assign(
            //         product,
            //         response.data.Attributes
            //     );
            //     // this.commit('updateProduct', updatedProduct);
            //     // context.dispatch('webSocketSend', {
            //     //     type: 'updateProduct',
            //     //     content: updatedProduct
            //     // });
            //     return updatedProduct;
            // }
            
            if (response.data) {
                analytics.track( 'deleteProduct', response.data);
                return response.data;
            }
        },
        
        
        
        async listCustomers(context){
            const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/customers');
            
            if (response.data) {
                this.commit('setCustomers', response.data.Items);
            }
        },
        
        async createCustomer(context, customer){
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/customers/', customer);
            // this.commit('createCustomer', response.data);
            // context.dispatch('webSocketSend', {
            //     type: 'createCustomer',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'createCustomer', response.data);
                return response.data;
            }
        },

        async updateCustomer(context, customer){
            // this.$segment.track(NAME_YOUR_TRACK, 
            //   //optional properties
            //   { 
            //     name: "it's your track name",
            //     category: "ps_metrics",
            //   })
            // 
            // return
            
            
            const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/customers/' + customer.customerId, customer);
            // this.commit('updateCustomer', response.data);
            // context.dispatch('webSocketSend', {
            //     type: 'updateCustomer',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'updateCustomer', response.data);
                return response.data;
            }
        },
        
        async deleteCustomer(context, customer){
            const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/customers/' + customer.customerId);
            
            if (response.data) {
                analytics.track( 'deleteCustomer', response.data);
                return response.data;
            }
            
            // if (response.data && response.data.Attributes) {
            //     const updatedCustomer = Object.assign(
            //         customer,
            //         response.data.Attributes
            //     );
            //     // this.commit('updateCustomer', updatedCustomer);
            //     // context.dispatch('webSocketSend', {
            //     //     type: 'updateCustomer',
            //     //     content: updatedCustomer
            //     // });
            //     return updatedCustomer;
            // }
        },
        
        
        
        async listNotes(context){
            const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/notes');
            
            if (response.data) {
                this.commit('setNotes', response.data.Items);
            }
        },
        
        async createNote(context, note){
            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/notes/', note);
            // this.commit('createNote', response.data);
            // context.dispatch('webSocketSend', {
            //     type: 'createNote',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'createNote', response.data);
                return response.data;
            }
        },
        
        async updateNote(context, note){
            const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/notes/' + note.noteId, note);
            // this.commit('updateNote', response.data);
            // context.dispatch('webSocketSend', {
            //     type: 'updateNote',
            //     content: response.data
            // });
            
            if (response.data) {
                analytics.track( 'updateNote', response.data);
                return response.data;
            }
        },
        
        async deleteNote(context, note){
            const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/notes/' + note.noteId);
            // if (response.data && response.data.Attributes) {
            //     const updatedNote = Object.assign(
            //         note,
            //         response.data.Attributes
            //     );
            // 
            //     // this.commit('updateNote', updatedNote);
            //     // context.dispatch('webSocketSend', {
            //     //     type: 'updateNote',
            //     //     content: updatedNote
            //     // });
            // 
            //     return updatedNote;
            // }
            
            if (response.data) {
                analytics.track( 'deleteNote', response.data);
                return response.data;
            }
        },
        
        
        
        async listArticles(context){
            const contentful = require('contentful');
            
            const client = contentful.createClient({
                space: process.env.VUE_APP_BLOG_SPACE_ID,
                accessToken: process.env.VUE_APP_BLOG_ACCESS_TOKEN,
            });
            
            let locale;
            if (context.state.account.locale.language == 'nb') {
                locale = 'nb';
            }
            else {
                locale = 'en-US';
            }
            
            const response = await client.getEntries({
                content_type: 'article',
                locale,
            });
            
            this.commit('setArticles', response);
        },
        
    }
})
