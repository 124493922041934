import Vue from 'vue'
import Router from 'vue-router'
import Store from './store'
// import {Auth} from 'aws-amplify';
import {Auth} from '@aws-amplify/auth';

Vue.use(Router)
Vue.use(Store)

import Axios from 'axios'
Vue.prototype.$axios = Axios;

const routes = [
    // www promo
    {
        path: '/',
        name: 'www',
        component: () => import('./views/www/Index.vue'), 
        meta: { category: 'Landing Page', publicPage: true }
    },
    
    // signup
    {
        path: '/signup',
        name: 'signup',
        component: () => import('./views/oauth2/SignUp.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // signin
    {
        path: '/signin',
        name: 'signin',
        component: () => import('./views/oauth2/SignIn.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // signout
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./views/oauth2/SignOut.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // resetpassword
    {
        path: '/resetpassword',
        name: 'resetpassword',
        component: () => import('./views/oauth2/ResetPassword.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    
    // resetpassword
    {
        path: '/newpasswordrequired',
        name: 'newpasswordrequired',
        component: () => import('./views/oauth2/NewPasswordRequired.vue'), 
        meta: { category: 'Account', publicPage: true }
    },
    // // oauth2 callbacks from social connections and stuff
    // {
    //     path: '/oauth2/callback',
    //     name: 'oauth2',
    //     component: () => import('./views/oauth2/Callback.vue'), 
    //     meta: { category: 'Account', publicPage: true }
    // },

    // User Logged in
    // Now, Choose account
    {
        path: '/accounts',
        name: 'accounts',
        component: () => import('./views/oauth2/Accounts.vue'), 
        meta: { category: 'Account'}
    },
    


    // User Logged in
        // Account is chosen!
        {
            path: '/app/:accountId/dashboard',
            name: 'dashboard',
            component: () => import('./views/app/dashboard/Index.vue'), 
            meta: { category: 'dashboard'}
        },
        {
            path: '/app/:accountId/customers',
            name: 'customers',
            component: () => import('./views/app/customers/Index.vue'), 
            meta: { category: 'customers'}
        },
        {
            path: '/app/:accountId/products',
            name: 'products',
            component: () => import('./views/app/products/Index.vue'), 
            meta: { category: 'products'}
        },
        {
            path: '/app/:accountId/offers',
            name: 'offers',
            component: () => import('./views/app/offers/Index.vue'), 
            meta: { category: 'offers'}
        },
            {
                path: '/app/:accountId/offers/create/:offerId?',
                name: 'offers-create',
                component: () => import('./views/app/offers/Create.vue'), 
                meta: { category: 'offers'}
            },
            {
                path: '/app/:accountId/offers/:offerId',
                name: 'offers-show',
                component: () => import('./views/app/offers/Index.vue'), 
                meta: { category: 'offers'}
            },
            
        {
            path: '/app/:accountId/learn',
            name: 'learn',
            component: () => import('./views/app/learn/Index.vue'), 
            meta: { category: 'learn'}
        },
            {
                path: '/app/:accountId/learn/:articleId',
                name: 'article',
                component: () => import('./views/app/learn/Show.vue'), 
                meta: { category: 'article'}
            },
        {
            path: '/app/:accountId/settings',
            name: 'settings',
            component: () => import('./views/app/settings/Index.vue'), 
            meta: { category: 'settings'}
        },
            {
                path: '/app/:accountId/settings/:section/:subSection?',
                name: 'settings-section',
                component: () => import('./views/app/settings/Index.vue'), 
                meta: { category: 'settings'}
            },
        
        {
            path: '/app/:accountId/chat',
            name: 'chat',
            component: () => import('./views/app/Chat.vue'), 
            meta: { category: 'Chat'}
        },


]

const router = new Router({
    mode: 'history', // default commented out
    // base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    // console.log('Store.getters.setUser', Store.getters.getUser);
    
    if (to.query.webview) {
        if (to.query.webview == 'true') {
            Store.commit('setIsWebView', true);
            console.log('true');
        }
        else {
            Store.commit('setIsWebView', false);
            console.log('false');
        }
    }
    
    if (to.matched.some(record => !record.meta.publicPage)) {
        // console.log('route requiresSignin TRUE');
        
        if (! Store.getters.getUser ){
            try {
                // console.log('user not set');
                
                const user = await Auth.currentAuthenticatedUser();
                // Store.state.signedIn = true;
                
                const jwt = user
                    .getSignInUserSession()
                    .getIdToken()
                    .getJwtToken();
                
                Store.commit('setUser', user);
                // console.log('setUser user', user);
                
                // Store.commit('setAuthorizationHeader', jwt);
                
                // console.log('--- Segment --- Identify user', user.username, user.attributes.email );
                
                
                
                // Get the active account from the url IF the State is empty.
                if ( 
                    to.params.accountId && 
                    !Store.getters.getAccount){ //&& !Store.state.account.accountId
                    
                    const accountId = to.params.accountId;
                    // console.log('accountId', accountId);
                    
                    // fetch account!
                    const response = await Axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+accountId);
                    if (!response.data) {
                        throw Error('Could not connect to this account');
                    }
                    const account = response.data;
                    
                    // set account!
                    // Store.commit('setAccount', account);
                    
                    await Store.dispatch('setAccount', account);
                    
                    // // get account!
                    // const currentAccount = Store.getters.getAccount;
                    // console.log('currentAccount', currentAccount);
                    
                    // const response_account = await Axios.get(Store.state.apiEndpoint + '/accounts/' + accountId);
                    // console.log('currentAccount', currentAccount);
                    
                    // // console.log('Account Login router.js');
                    // // console.log('state accountBrandKit should be reset so it can be refetched!');
                    // // console.log('----> state accountBrandKit',Store.state.accountBrandKit );
                    // 
                    // // Store.state.account = response_account.data.account;
                    // const account = response_account.data.account;
                    // Store.commit('setAccountGeneral', account);
                    // 
                    // // Store.state.account.users = response_account.data.users;
                    // const users = response_account.data.users;
                    // Store.commit('setAccountUsers', users);
                
                    // Store.state.usersAccounts.find( a => {
                    //     if (a.accountId == accountId) {
                    //         Store.state.account.accountName = a.accountName;
                    //     }
                    // })
                
                    // console.log('--- Segment --- Group accountId:', accountId, Store.state.account.accountName);
                    // await window.analytics.group( accountId, Store.state.account);
                }
                
                // // console.log('--- Segment --- Identify user', user.username, user.attributes.email );
                // // await window.analytics.identify( user.username, user.attributes);
                // 
                // // Get all the account that this user is participating in
                // const response = await Axios.get(Store.state.apiEndpoint + '/account/usersAccounts');
                // Store.state.usersAccounts = response.data;
                // 
                // // Get the active account from the url IF the State is empty.
                // if ( to.params.accountId && !Store.state.account.accountId){
                //     const accountId = to.params.accountId;
                //     const response_account = await Axios.get(Store.state.apiEndpoint + '/account/accounts/' + accountId);
                // 
                //     // console.log('Account Login router.js');
                //     // console.log('state accountBrandKit should be reset so it can be refetched!');
                //     // console.log('----> state accountBrandKit',Store.state.accountBrandKit );
                // 
                //     // Store.state.account = response_account.data.account;
                //     const account = response_account.data.account;
                //     Store.commit('setAccountGeneral', account);
                // 
                //     // Store.state.account.users = response_account.data.users;
                //     const users = response_account.data.users;
                //     Store.commit('setAccountUsers', users);
                // 
                //     // Store.state.usersAccounts.find( a => {
                //     //     if (a.accountId == accountId) {
                //     //         Store.state.account.accountName = a.accountName;
                //     //     }
                //     // })
                // 
                //     // console.log('--- Segment --- Group accountId:', accountId, Store.state.account.accountName);
                //     // await window.analytics.group( accountId, Store.state.account);
                // }
                next()
            } 
            catch (err) {
                console.error(err);
                Store.commit('setUser', null);
                // Store.commit('setAccount', null);
                await Store.dispatch('setAccount', null);
                
                next({
                    path: '/signin'
                });
            }
        }
        else {
            next()
        }
    }
    else {
        // console.log('requiresSignin FALSE');
        next()
    }
    
    next()
    
})

router.afterEach( async (to, from) => {
    // page tracker
    analytics.page( to.name , { 
        path: to.path,
        category: to.meta && to.meta.category ? to.meta.category : null,
    });
})

router.onReady( async (to, from) => {
    // if (to.query && to.query.embedParams) {
    //     const embedParams = JSON.parse( decodeURIComponent( to.query.embedParams ) );
    //     Store.state.embedParams = embedParams;
    //     // console.log('embedParams: ', embedParams);
    // }
    // 
    // // when router is ready, be sure to fetch the brandkit from AccountsMixins (router.app = VUE instance)
    // if (Store.state.signedIn) {
    //     await router.app.brandKitReset();
    //     await router.app.brandKitFetch();
    // }
})

export default router;
