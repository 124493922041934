// ApiMixins.js

export default {
    components: {
        'InputSingleline': () => import('@/components/input/InputSingleline.vue'),
        'InputMultiline': () => import('@/components/input/InputMultiline.vue'),
        'InputSelect': () => import('@/components/input/InputSelect.vue'),
        'InputUpload': () => import('@/components/input/InputUpload.vue'),
    },
    
    data() {
        return {
            // industries: [
            //     'Business',
            //     'Consulting & Coaching',
            //     'Services & Maintenance',
            //     'Technology & Apps',
            //     'Advertising & Marketing',
            //     'Automotive & Cars',
            //     'Real Estate',
            //     'Finance & Law',
            //     'Pets & Animals',
            // ],
            languages: [
                {
                    name: 'Norsk',
                    code: 'nb',
                },
                {
                    name: 'English',
                    code: 'en',
                },
            ],
            currencies: [
                {
                    name: 'Norske Kroner',
                    code: 'NOK',
                    format: 'kr ',
                    position: 'left',
                    locale: 'nb',
                },
                {
                    name: 'US Dollar',
                    code: 'USD',
                    format: '$',
                    position: 'left',
                    locale: 'en',
                },
            ],
            timezones: [
                '0',
                '+1',
            ]
        }
    },
    
    computed: {
        accountId() {
            if (this.$store.getters.getAccount) {
                return this.$store.getters.getAccount.accountId;
            }
            return false;
        },
        
        account(){
            return this.$store.getters.getAccount;
        },
        
    
        inApplication(){
            if (this.$route && this.$route.path.startsWith('/app/') ) {
                return true;
            }
            return false;
        },
        
        isMobile(){
            if ( window.innerWidth < 640 ) {
                return true
            }
            
            return false
            
            // create(){
            //     console.log('created');
            //     this.windowWidth = window.innerWidth;
            // 
            //     window.onresize = () => {
            //         this.windowWidth = window.innerWidth;
            //     }
            // }
            // return this.$store.getters.getIsMobile;
        },
        
        isWebView(){
            return this.$store.getters.getIsWebView;
        },
    },
    
    
    methods: {
        currencyFormat( price, symbol = true, locale = this.account.locale ){
            // console.log('locale', locale);
            
            const currency = this.currencies.find( c => c.code == locale.currency);
            let formatedString;
            
            price = parseFloat(price).toLocaleString( currency.locale, {minimumFractionDigits: 2, maximumFractionDigits: 2,} );
            
            if (symbol) {
                if (currency.position == 'left') {
                    formatedString = currency.format + price;
                }
                else {
                    formatedString = price + currency.format;
                }
            }
            else {
                formatedString = price;
            }
            
            return formatedString;
        },
        
        // accounts
        async accountsCreate( account ) {
            const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/accounts', account);
            return response.data;
        },
        
        async accountsList() {
            const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts');
            if (response.data) {
                response.data = response.data.filter( a => a.accountId != 'GivviGoAdmin')
            }
            return response.data;
        },
        
        async accountsGet( accountId ) {
            const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+accountId);
            return response.data;
        },
        
        // async accountUsersList() {
        //     const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+ this.accountId +'/users');
        //     return response.data;
        // },
        
        async accountUsersInvite( data ) {
            const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+ this.accountId +'/users/invite', data);
            return response.data;
        },
        
        // customers
        customerIdToCustomer( customerId ){
            const customers = this.$store.getters.getCustomers;
            let customer = {
                customerId,
            };
            
            if (customers && customers.length) {
                customer = customers.find(c => c.customerId == customerId);
                
                if (
                    customer &&
                    customer.contacts && 
                    customer.contacts.length > 0 ) {
                
                    customer.primaryContact = customer.contacts.find(c => c.isPrimary) || customer.contacts[0];
                }
            }
            
            return customer;
        },
        
        humanFileSize(bytes, si = false, dp = 1) {
            const thresh = si ? 1000 : 1024;

            if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }

            const units = si ?
                ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
                ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
            let u = -1;
            const r = 10 ** dp;

            do {
                bytes /= thresh;
                ++u;
            } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


            return bytes.toFixed(dp) + ' ' + units[u];
        },
        
        generateUID( ts = true) {
            // https://stackoverflow.com/questions/6248666/how-to-generate-short-uid-like-ax4j9z-in-jsfunction generateUID() {
            // I generate the UID from two parts here 
            // to ensure the random number provide enough bits.
            // And Kristoffer has added a timestamp for meta...
            var timestamp = new Date().getTime();
            var firstPart = (Math.random() * 46656) | 0;
            var secondPart = (Math.random() * 46656) | 0;
            firstPart = ("000" + firstPart.toString(36)).slice(-3);
            secondPart = ("000" + secondPart.toString(36)).slice(-3);
            
            if( ts ) {
                return timestamp +'.'+ firstPart +'.'+ secondPart;
            }
            else {
                return firstPart +'.'+ secondPart;
            }
        },
        
        // files
        async filesUpload( file ) {
            // will return 
            const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/' + this.accountId + '/files', file);
            return response.data;
        },
        
        async filesDelete(fileId) {
            const response = await this.$axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+this.accountId+'/files/' + fileId);
            return response.data;
        },
        
        
        fileToUrl( file ){
            return process.env.VUE_APP_FILES_ENDPOINT + '/'+this.accountId+'/' + file.fileId +'.'+ file.extension;
        },
    },
}
