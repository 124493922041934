<template>
    <nav class="grid grid-flow-col gap-2">
        <a 
            v-for="(tab, index) in tabs"
            :key="tab.alias"
            @click="onClick(tab)"
            class="
                rounded-full flex cursor-pointer items-center leading-none
                pr-3 pl-1 py-1
                md:pl-2 md:py-2 md:pr-4
            "
            :class="['tab-item', active == tab.alias ? 'bg-cyan-dark text-white' : 'text-gray-600 hover:bg-white ']">
            
            <strong 
                class="flex items-center justify-center w-5 md:w-6 h-5 md:h-6 rounded-full mr-2 md:mr-4 text-sm"
                :class="[ active == tab.alias ? 'bg-white text-cyan-dark' : ' bg-gray-500 text-white ']">
                {{index+1}}
            </strong>
            
            {{tab.name}}
        </a>
    </nav>
</template>

<script>
    export default {
        props: [
            'tabs',
            'active',
        ],
        
        methods: {
            onClick( tab ){
                if (tab.route) {
                    this.$router.push( tab.route );
                }
                else {
                    this.$emit('click', tab);
                }
            }
        }
    }
</script>
