<template>
    <div 
        class="w-full h-full bg-gray-200 flex "
        :class="!isMobile ? 'flex-row' : 'flex-col' ">
        
        <NavigationLeft 
            v-show="inApplication"
            v-if="!isMobile"
            class="flex-none flex"
        />
        
        <!-- <div 
            class="fixed bottom-0 right-0 h-8 flex text-white font-bold text-center"
            style="z-index: 1000;">
            
            <div class="w-16 hidden sm:block bg-yellow-500">sm</div>
            <div class="w-16 hidden md:block bg-red-500">md</div>
            <div class="w-16 hidden lg:block bg-blue-500">lg</div>
            <div class="w-16 hidden xl:block bg-gray-500">xl</div>
            <div class="w-16 hidden 2xl:block bg-orange-500">2xl</div>
        </div> -->
       
        
        <div class="flex-grow overflow-hidden h-full">
            <router-view id="routerView" />
        </div>
        
        <NavigationBottomMobile 
            v-show="inApplication"
            v-if="isMobile"
            class="flex-none bg-black"
            style="z-index: 1000; box-shadow: 0 -5px 10px rgba(0,0,0,.03);"
        />
        
        <notifications 
            classes="notification" 
            position="top center"
        />
        
        <router-link 
            :to="{ name: 'chat', params: { accountId,} }"
            v-if="appVersion && appVersion.version && appVersion.stage == 'dev'"
            class="fixed top-0 right-0 mt-4 mr-4  text-xs pointer-events-none bg-orange-500 text-white px-1 rounded">
            
            DEV v{{appVersion.version}}
        </router-link>
        
        
        
        
        <link rel="preload" href="/assets/illustrations/undraw_server_down_s4lk.svg" as="image" >
        <div 
            v-show="showDisconnected"
            class="fixed bg-white p-12 inset-0 flex items-center justify-center"
            style="z-index: 1001;">
            
            <transition 
                name="pop"
                appear>
                
                <div v-if="showDisconnected">
                    <img 
                        src="/assets/illustrations/undraw_server_down_s4lk.svg" 
                        class="object-contain mb-8 w-48 h-48 mx-auto"
                        :alt="showDisconnected.message"
                    />
                    <p v-html="showDisconnected.message"/>
                </div>
            </transition>
        </div>
        
        
        
        <div v-show="showAddToHomeScreen">
            <div 
                v-if="isIos() == 'ipad' || isIos() == 'iphone'"
                class="fixed left-0 right-0 flex items-center justify-center m-8"
                :class="isIos() == 'ipad' ? 'top-0' : 'bottom-0'"
                style="z-index: 1000; ">
                
                <div 
                    class="bg-white p-8 rounded-lg flex flex-col items-center justify-center relative"
                    style="box-shadow: 0 0 20px rgba(0,0,0,.5);">
                        
                    <div 
                        class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
                        @click="showAddToHomeScreen = false;">
                        
                        <i class="fas fa-times"></i>
                    </div>
                    
                    <div class="flex flex-col items-center">
                        <div>
                            <img 
                                src="/android-chrome-192x192.png"
                                class="w-16 h-16 bg-clay-light rounded-xl p-2 my-2"
                            />
                        </div>
                        <div class="text-left">
                            <h2 class="text-black text-center">Install App on {{ isIos() == 'ipad' ? 'IPad' : 'IPhone' }}</h2>
                            
                            <div class="flex mt-4">
                                <strong class="font-bold w-6">1</strong>
                                <div>
                                    Tap <span class="w-4 h-4 inline-flex items-center justify-center">
                                    <svg viewBox="0 0 1000 1000" class="w-full"><path fill="royalblue" d="M780,290H640v35h140c19.3,0,35,15.7,35,35v560c0,19.3-15.7,35-35,35H220c-19.2,0-35-15.7-35-35V360c0-19.2,15.7-35,35-35h140v-35H220c-38.7,0-70,31.3-70,70v560c0,38.7,31.3,70,70,70h560c38.7,0,70-31.3,70-70V360C850,321.3,818.7,290,780,290z M372.5,180l110-110.2v552.7c0,9.6,7.9,17.5,17.5,17.5c9.6,0,17.5-7.9,17.5-17.5V69.8l110,110c3.5,3.5,7.9,5,12.5,5s9-1.7,12.5-5c6.8-6.8,6.8-17.9,0-24.7l-140-140c-6.8-6.8-17.9-6.8-24.7,0l-140,140c-6.8,6.8-6.8,17.9,0,24.7C354.5,186.8,365.5,186.8,372.5,180z"/></svg></span> located here at the {{ isIos() == 'ipad' ? 'top' : 'bottom' }}
                                </div>
                            </div>
                            <div class="flex">
                                <strong class="font-bold w-6">2</strong>
                                <div>
                                    <span v-if="isIos() == 'iphone'">Swipe down.</span>
                                    Tap <span class="text-black">Add to Home Screen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-else-if="!showAppInstalled && isMobile"
                class="fixed bottom-0 left-0 right-0 flex items-center justify-center m-8"
                style="z-index: 1000; ">
                
                <div 
                    class="bg-white p-8 rounded-lg flex flex-col items-center justify-center relative"
                    style="box-shadow: 0 0 20px rgba(0,0,0,.5);">
                    
                    <div 
                        class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
                        @click="showAddToHomeScreen = false;">
                        
                        <i class="fas fa-times"></i>
                    </div>
                    
                    <div class="flex items-center">
                        <img 
                            src="/android-chrome-192x192.png"
                            class="w-16 h-16 flex-none bg-clay-light rounded-xl p-2"
                        />
                    </div>
                    <div class="mt-2 text-center">
                        <h2 class="text-black">Install App</h2>
                        Add icon to your home screen
                         <!-- and <br>receive push notifications -->
                    </div>
                    <button
                        class="button submit mt-6"
                        @click="addToHomeScreen()">
                        Add to Home Screen
                    </button>
                </div>
            </div>
        </div>
        
        <div 
            v-show="showAppInstalled "
            class="fixed bg-white p-12 bottom-0 left-0 right-0 flex flex-col border-t"
            style="z-index: 1001; box-shadow: 0 -10px 10px rgba(0,0,0,.1);">
            
            <div 
                class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
                @click="showAppInstalled = false; showAddToHomeScreen = false;">
                
                <i class="fas fa-times"></i>
            </div>
            
            <div class="flex flex-col items-center text-center">
                <strong class="text-black">App Installed</strong>
                <div>You should now have an icon on your home screen</div>
            </div>
        </div>
        
        <!-- 
        <div 
            v-show="showInstallPromotion && !showAppInstalled"
            class="fixed bg-white p-12 bottom-0 left-0 right-0 flex flex-col border-t"
            style="z-index: 1000; box-shadow: 0 -10px 10px rgba(0,0,0,.1);">
            
            <div 
                class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
                @click="showInstallPromotion = false; showAppInstalled = false;">
                
                <i class="fas fa-times"></i>
            </div>
            
            <div class="flex flex-col items-center text-center">
                <img 
                    src="/android-chrome-192x192.png"
                    class="w-20 h-20 flex-none mb-6 bg-clay-light rounded-xl p-2"
                />
                <div class="w-64">
                    <strong class="text-black">Install GivviGo</strong>
                    <div>Add icon to your home screen and receive push notifications</div>
                    <button
                        class="button submit mt-6"
                        @click="addToHomeScreen()">
                        Add to Home Screen
                    </button>
                </div>
            </div>
        </div>
         -->
        
        <!-- <div 
            v-show="showAppInstalled"
            class="fixed bg-white p-12 bottom-0 left-0 right-0 flex flex-col border-t"
            style="z-index: 1001; box-shadow: 0 -10px 10px rgba(0,0,0,.1);">
            
            <div 
                class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
                @click="showInstallPromotion = false; showAppInstalled = false;">
                
                <i class="fas fa-times"></i>
            </div>
            
            <div class="flex flex-col items-center text-center">
                <strong class="text-black">App Installed</strong>
                <div>You should now have an icon on your home screen</div>
            </div>
        </div> -->
        
        <!-- 
        <div class="fixed bg-white p-12 top-0 left-0 right-0 text-center border-b">
            <div v-if="notificationPermission == 'default'">
                <button
                    class="button submit mr-8"
                    @click="requestNotificationPermission()">
                    Allow Notifications
                </button>
            </div>
            <div v-else-if="notificationPermission == 'denied'">
                You have to reset Notification settings to allow them
            </div>
            <div v-else>
                <button
                    class="button submit mr-8"
                    @click="newNotification()">
                    New Notifications
                </button>
                
                <button
                    class="button submit"
                    @click="subscribeToNotifications()">
                    Subscribe Notifications
                </button>
            </div>
        </div>
        -->
    </div>
</template>

<script>
    // let deferredPrompt;
    
    export default {
        name: 'App',
        components: {
            'NavigationLeft': () => import('@/components/NavigationLeft.vue'),
            'NavigationBottomMobile': () => import('@/components/NavigationBottomMobile.vue'),
        },
        
        computed: {
            
            // inApplication(){
            //     if (this.$route && this.$route.path.startsWith('/app/') ) {
            //         return true;
            //     }
            //     return false;
            // },
            // 
            // isMobile(){
            //     if ( this.windowWidth < 640 ) {
            //         return true
            //     }
            //     return false
            // 
            //     // return this.$store.getters.getIsMobile;
            // },
        
            // wSConnectionLastPong(){
            //     return this.$store.getters.getWSConnectionLastPong;
            // },
            
            showDisconnected(){
                return this.$store.getters.getShowDisconnected;
            },
        },
        
        data () {
            return {
                pollingMessages: null,
                pollingOffers: null,
                pollingTrackings: null,
                pollingMessagesInterval: 10,
                pollingOffersInterval: 30,
                pollingTrackingsInterval: 30,
                // windowWidth: window.innerWidth,
                appVersion: null,
                showAddToHomeScreen: false,
                // swRegistration: null,
                // showInstallPromotion: false,
                showAppInstalled: false,
                deferredPrompt: null,
                // notificationPermission: null,
                pollingWebSocketConnection: null,
            }
        },
        
        methods: {
            pollMessages() {
                // console.log('pollingMessages started');
                
                this.pollingMessages = setInterval( () => {
                    if (this.inApplication) {
                        // console.log('pollingMessages');
                        this.$store.dispatch('listMessages', {method: 'listActiveOffers'});
                    }
                    else {
                        // console.log('NOT IN APPLICATION - pollingMessages');
                    }
                }, this.pollingMessagesInterval * 1000 )
            },
            
            pollOffers() {
                // console.log('pollingOffers started');
                
                this.pollingOffers = setInterval( () => {
                    if (this.inApplication) {
                        // console.log('pollingOffers');
                        this.$store.dispatch('listOffers');
                    }
                    else {
                        // console.log('NOT IN APPLICATION - pollingOffers');
                    }
                }, this.pollingOffersInterval * 1000 )
            },
            
            pollTrackings() {
                // console.log('pollingTrackings started');
                
                this.pollingTrackings = setInterval( () => {
                    if (this.inApplication) {
                        // console.log('pollingTrackings');
                        this.$store.dispatch('listTrackings', {method: 'listLatest'});
                    }
                    else {
                        // console.log('NOT IN APPLICATION - pollingTrackings');
                    }
                }, this.pollingTrackingsInterval * 1000 )
            },
            
            pollWebSocketConnection() {
                // console.log('pollWebSocketConnection started');
                
                // this.pollingWebSocketConnection = setInterval( () => {
                // 
                //     console.log( new Date() - this.wSConnectionLastPong );
                // 
                //     // if (window.wSConnection  && window.wSConnection !== 'undefined') {
                //     //     // console.log('window.wSConnection.readyState: ');
                //     //     // console.log(window.wSConnection.readyState);
                //     // 
                //     //     if (
                //     //         window.wSConnection.readyState === 3 ||
                //     //         window.wSConnection.readyState === 2 ){
                //     // 
                //     //         console.log('try reconnect!');
                //     //         // this.$store.dispatch('webSocketConnect');
                //     //     }
                //     // 
                //     //     // if (this.inApplication) {
                //     //     //     // console.log('pollingTrackings');
                //     //     //     this.$store.dispatch('listTrackings', {method: 'listLatest'});
                //     //     // }
                //     //     // else {
                //     //     //     // console.log('NOT IN APPLICATION - pollingTrackings');
                //     //     // }
                //     // }
                //     // else {
                //     //     console.log('no ws connection yet.');
                //     // }
                // }, 2000 )
            },
            
            pageRefresh(){
                this.$router.go( this.$router.currentRoute );
            },
            
            async addToHomeScreen(){
                // Installation must be done by a user gesture! Here, the button click
                // hide our user interface that shows our A2HS button
                // Show the prompt
                this.deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                const choiceResult = await this.deferredPrompt.userChoice;
            
                if ( choiceResult.outcome === 'accepted' ) {
                    console.log('User accepted the A2HS prompt');
                    // this.showInstallPromotion = false;
                }
                else {
                    console.log('User dismissed the A2HS prompt');
                }
            
                this.deferredPrompt = null;
            },
            
            // async requestNotificationPermission(){
            //     this.notificationPermission = await Notification.requestPermission();
            //     // console.log('this.notificationPermission', this.notificationPermission);
            // 
            //     if (this.notificationPermission !== 'granted') {
            //         alert('You have not given GivviGo permissions to show notifications.');
            //     }
            // },
            
            // async newNotification(){
            //     console.log('newNotification');
            // 
            //     if ( await Notification.requestPermission() === 'granted') {
            //         const sw = await navigator.serviceWorker.ready;
            // 
            //         const body = 'You have an offer on '+  new Date();  +' Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
            // 
            //         sw.showNotification('Vibration Sample', {
            //             body,
            //             icon: '/android-chrome-192x192.png',
            //             vibrate: [200, 100, 200, 100, 200, 100, 200],
            //             tag: 'vibration-sample'
            //         });
            //     }
            //     else {
            //         console.log('not ready...');
            //     }
            // },
            
            // async subscribeToNotifications(){
            //     try {
            //         const sw = await navigator.serviceWorker.ready;
            //         console.log( sw );
            // 
            //         const pushSubscription = await sw.pushManager.subscribe({
            //             userVisibleOnly: true,
            //             applicationServerKey: '',
            //         });
            // 
            //         console.log(pushSubscription.endpoint);
            //     } 
            //     catch (error) {
            //         console.error(error);
            //     }
            // },
            
            // checkIsMobile(){
            //     this.windowWidth = window.innerWidth;
            // 
            //     if ( this.windowWidth < 640 ) {
            //         this.$store.commit('setIsMobile', true);
            //     }
            //     else {
            //         this.$store.commit('setIsMobile', false);
            //     }
            // },
            
            isIos() { // Detects if device is on iOS 
                const userAgent = window.navigator.userAgent.toLowerCase();
                
                if ( /iphone|ipod/.test( userAgent ) ) {
                    return 'iphone'
                }
                else if ( /ipad/.test( userAgent ) ) {
                    return 'ipad'
                }
                else if ( (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad' ) {
                    return 'ipad'
                }
                else {
                    return
                }
            },
            
            isInStandaloneMode() { // Detects if device is in standalone mode
                // return false
                
                if (
                    'standalone' in window.navigator && 
                    window.navigator.standalone ){
                
                    return true
                }
            }
        },
        
        async created() {
            const language = navigator.language.split('-')[0];
            
            switch (language) {
                case 'nb':
                case 'nn':
                case 'no':
                    this.$i18n.locale = 'nb';
                    break;
                default:
                    this.$i18n.locale = 'en';
            }
            
            // if (analytics) {
            // 
            //     console.log(analytics);
                // analytics.load( process.env.VUE_APP_SEGMENT_KEY );
            // }
            
            
            // if ( 
            //     // process && process.env && process.env.NODE_ENV == 'development' &&
            //     window.location.hostname == 'localhost') {
            // 
            //     console.log('not polling! using websockets');
            //     this.pollingMessagesInterval = 10*10;
            //     this.pollingOffersInterval = 30*10;
            //     this.pollingTrackingsInterval = 30*10;
            // }
            // else {
            //     this.pollMessages();
            //     this.pollOffers();
            //     this.pollTrackings();
            // }
            
            
            // this.pollWebSocketConnection();
            
            // if ( Notification.permission && Notification.permission != 'granted') {
            //     this.notificationPermission = Notification.permission;
            // }
            
            
            
            // Checks if should display install popup notification:
            if ( 
                this.isIos() && 
                !this.isInStandaloneMode() ) {
                
                this.showAddToHomeScreen = true;
            }
            
            
            
            if ('serviceWorker' in navigator) {
                // Register a service worker hosted at the root of the site using the default scope.
                try {
                    this.swRegistration = await navigator.serviceWorker.register('/sw.js?v='+process.env.VUE_APP_VERSION);
                    // console.log('Service worker registration succeeded:', this.swRegistration);
                }
                catch (error) {
                    // console.log('Service worker registration failed:', error);
                }
            }
            else {
                // console.log('Service workers are not supported.');
            }
            
            window.addEventListener('beforeinstallprompt', (event) => {
                // console.log('show beforeinstallprompt');
                // console.log(event);
                this.showAddToHomeScreen = true;
                event.preventDefault();
                this.deferredPrompt = event;
            });
            
            window.addEventListener('appinstalled', (event) => {
                // this.addToHomeScreen = false;
                this.showAddToHomeScreen = false;
                this.showAppInstalled = true;
                // Log install to analytics
            
                // console.log('show appinstalled');
                // console.log(event);
                // alert('Install was successfull');
                // event.preventDefault();
            });
            
            window.addEventListener('offline', (event) => { 
                this.$store.commit('setShowDisconnected', {
                    message: this.$t('general.youAreOffline'),
                });
            });
            
            
            window.addEventListener('online', (event) => { 
                this.$store.commit('setShowDisconnected', null);
            });
            
            // <!-- if (navigator.onLine) {
            //     this.commit('setShowRefresh', 'You have been disconnected from the server in over '+Math.round(millisecondsOld/1000) +' seconds. Will refresh GivviGo now');
            //     // router.go( router.currentRoute );
            // }
            // else {
            //     alert('Could not refresh since you are offline.');
            //     this.commit('showRefresh', {
            //         code: 2,
            //         text: 'It seems like you are offline',
            //     });
            // } -->
            // <!-- Please refresh... -->
            // 
            // return this.$store.getters.getShowRefresh;
            
            this.appVersion = {
                version: process.env.VUE_APP_VERSION,
                stage: process.env.NODE_ENV == 'development' ? 'dev' : '',
            };
        },
        
        beforeDestroy() {
            // console.log('beforeDestroy');
            // try {
            //     // clearInterval( this.pollingMessages );
            //     // clearInterval( this.pollingOffers );
            //     // clearInterval( this.pollTrackings );
            //     // 
            //     // clearInterval( this.pollWebSocketConnection );
            //     clearInterval( this.pollingWebSocketConnection );
            // } 
            // catch (e) {
            //     console.log('Could not clear polling');
            //     console.error(e);
            // }
        },
    }
</script>
