<template>
    <div class="modal">
        <div class="dim" @click="$emit('close')"></div>
        
        <div 
            class="inside relative" 
            :style="{ 'width': width }"
            :class="insideClasses">
            
            <div class=" h-full">
                <header v-if="hasHeaderSlot">
                    <h3 class="text-black">
                        <slot name="header"></slot>
                    </h3>
                </header>
                
                <slot></slot>
                
                <footer 
                    v-if="hasFooterSlot"
                    class="bg-gray-200 p-4">
                    
                    <slot name="footer"></slot>
                </footer>
                
                <div 
                    class="close text-gray hover:text-black absolute top-0 right-0 mt-6 mr-6" 
                    :class="closeClasses"
                    @click="$emit('close')">
                    
                    <i class="fas fa-times"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                default: '24rem',
            },
            insideClasses: {
                default: 'bg-white',
            },
            closeClasses: {
                default: '',
            }
        },
        
        data() {
            return {
                
            }
        },
        
        computed: {
            hasHeaderSlot () {
                return !!this.$slots['header']
            },
            hasFooterSlot () {
                return !!this.$slots['footer']
            }
        },
        
        created() {
            this.$nextTick( () => {
                if (
                    this.$scopedSlots.default()[0].context && 
                    this.$scopedSlots.default()[0].context.$refs && 
                    this.$scopedSlots.default()[0].context.$refs.autofocus) {
                    
                    this.$scopedSlots.default()[0].context.$refs.autofocus.focus();
                }
            });
    
            document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    this.$emit('close');
                }
            })
        },
    }
</script>

<style lang="scss" scoped>
    .modal {
        z-index: 1000;
        @apply flex;
        @apply flex-col;
        @apply justify-center;
        @apply items-center;
        @apply fixed;
        @apply inset-0;
    }
    
    .dim {
        @apply opacity-25;
        @apply bg-black;
        @apply fixed;
        @apply inset-0;
        z-index: 1000;
    }
    
    .inside {
        z-index: 1001;
        @apply shadow-lg;
        @apply rounded-lg;
        @apply overflow-hidden;
        
        .panel {
            @apply mb-0;
        }
    }
    
</style>
